<template>
  <div class="space-y-8">
    <div class="tw-section">
      <div v-html="md_HELLO_MSG_1"></div>
    </div>

    <div>
      <div class="text-white pb-2">시장 분위기 (테스트 중)</div>
      <div class="lv_section">
        <div class="space-y-2">
          <div>
            <div class="flex space-x-2">
              <div class="lvtitle">시장</div>
              <div class="flex space-x-1 font-size-xs2">
                <div class="lvx">평균</div>
                <div class="lvx">1M</div>
                <div class="lvx">3M</div>
                <div class="lvx">6M</div>
                <div class="lvx">1Y</div>
              </div>
            </div>
          </div>
          <div v-for="market in market_list" :key="market.title">
            <div class="flex space-x-2">
              <div class="lvtitle">{{ market.title }}</div>
              <div class="flex space-x-1 font-size-xs2">
                <div class="lvx font-size-xs2">
                  {{ get_percent(market.data[1]) }}
                </div>
                <div class="lvx font-size-xs2">
                  {{ get_percent(market.data[2]) }}
                </div>
                <div class="lvx font-size-xs2">
                  {{ get_percent(market.data[4]) }}
                </div>
                <div class="lvx font-size-xs2">
                  {{ get_percent(market.data[7]) }}
                </div>
                <div class="lvx font-size-xs3">
                  {{ get_percent(market.data[13]) }}
                </div>
              </div>
            </div>
          </div>

          <div class="text-right font-size-xs3">
            기준 데이터: {{ market_last_date }}
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="text-white pb-2">퇴직 연금 분산 (테스트 값)</div>
      <div class="grid grid-cols-2 gap-4">
        <div class="lv_section">
          <div class="lv_title">
            퇴직 연금 계좌<br />(선물/인버스 투자 불가)
          </div>
          <div class="pt-2 space-y-2">
            <div v-for="data in kr_acc_list" :key="data.key">
              <div class="flex justify-between text-xs">
                <div v-if="data.key != 0">
                  <a :href="get_naver_url(data.key)">
                    {{ data.name }}
                  </a>
                </div>
                <div v-else>{{ data.name }}</div>
                <div>{{ data.percent }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="lv_section">
          <div class="lv_title">미국 ETF 분산</div>
          <div class="pt-2 space-y-2">
            <div v-for="data in us_acc_list" :key="data.key">
              <div class="flex justify-between text-xs">
                <div v-if="data.key != 0" class="space-x-2">
                  {{ data.name }}
                  -
                  <small
                    ><b>
                      <a :href="get_naver_url(data.key)">
                        {{ data.key }}
                      </a>
                    </b></small
                  >
                </div>
                <div v-else>{{ data.name }}</div>
                <div>{{ data.percent }}</div>
              </div>
            </div>
          </div>
        </div>

        <!-- 내 분산 -->
        <div class="lv_section space-y-3">
          <div class="lv_title">모멘텀 분산 (시험중)</div>

          <div class="pt-2 space-y-2">
            <div v-for="data in my_dist_list" :key="data.key">
              <div class="flex justify-between text-xs">
                <div v-if="data.key != 0" class="space-x-2">
                  {{ data.name }}
                  -
                  <small
                    ><b>
                      <a :href="get_naver_url(data.key)">
                        {{ data.key }}
                      </a>
                    </b></small
                  >
                </div>
                <div v-else>{{ data.name }}</div>
                <div>{{ data.percent }}</div>
              </div>
            </div>
          </div>

          <div class="text-right font-size-xs3">2022-07-31 업데이트</div>
        </div>

        <!-- 분산투자 -->
      </div>
    </div>

    <!-- END -->
  </div>
</template>

<script>
import data_yfin from "@/assets/data_yfin.json";

export default {
  data() {
    return {
      news: [
        {
          id: 1,
          date: "2022.01",
          tags: ["#TAG_RECYCLING", "#TAG_PRODUCTIVITY"],
          title_ko: "테슬라는 2022년에 새로운 모델을 출시하지 않을 것",
          title_en: "Tesla will not launch any new models in 2022",
          link: "https://www.autocar.co.uk/car-news/new-cars/tesla-will-not-launch-any-new-models-2022",
        },
        {
          id: 2,
          date: "2022.01",
          tags: ["#TAG_VIRTUALIZATION"],
          title_ko: "KBS 드라마 촬영중 말 사망",
          title_en: "Horse died while filming KBS drama.",
          link: "https://news.sbs.co.kr/news/endPage.do?news_id=N1006613086",
        },
      ],
      market_last_date: "YYYY-MM",
      market_list: [],
      kr_acc_list: [
        { key: 360750, name: "TIGER 미국S&P500", percent: "17%" },
        { key: 294400, name: "KOSEF 200TR", percent: "2%" },
        { key: 148070, name: "KOSEF 국고채10년", percent: "25%" },
        { key: 411060, name: "KINDEX KRX금현물", percent: "15%" },
        { key: 284430, name: "KODEX 200미국채혼합", percent: "41%" }, // 4:6 빙율
      ],
      us_acc_list: [
        { key: "SPY", name: "S&P", percent: "30%" },
        { key: "TLT", name: "장기책", percent: "40%" },
        { key: "IEF", name: "중기채", percent: "15%" },
        { key: "GLD", name: "금", percent: "7.5%" },
        { key: "DBC", name: "원자재", percent: "7.5%" },
      ],
      my_dist_list: [
        { key: "SPY", name: "S&P 2x", percent: "44%" },
        { key: "TLT", name: "장기책", percent: "44%" },
        { key: "UUP", name: "달러", percent: "12%" },
      ],
    };
  },
  mounted() {
    console.log("mounted");
    this.$store.commitRoot("set_ui", this.$route.fullPath);

    console.log("mounted 2");

    this.market_last_date = data_yfin.metv[0].substring(0, 7);

    this.market_list.push({
      title: "메타버스",
      data: data_yfin.metv,
      // lv: data_yfin.metv[1],
      // levels: get_levels(data_yfin.metv[1]),
    });
    this.market_list.push({
      title: "바이오기술",
      data: data_yfin.bio,
      // lv: data_yfin.bio[1],
      // levels: get_levels(data_yfin.bio[1]),
    });
    this.market_list.push({
      title: "코인",
      data: data_yfin.coin,
      // lv: data_yfin.coin[1],
      // levels: get_levels(data_yfin.coin[1]),
    });
    this.market_list.push({
      title: "채권",
      data: data_yfin.tlt,
      // lv: data_yfin.tlt[1],
      // levels: get_levels(data_yfin.tlt[1]),
    });
    this.market_list.push({
      title: "금",
      data: data_yfin.gld,
      // lv: data_yfin.gld[1],
      // levels: get_levels(data_yfin.gld[1]),
    });
    this.market_list.push({
      title: "원자재",
      data: data_yfin.dbc,
      // lv: data_yfin.dbc[1],
      // levels: get_levels(data_yfin.dbc[1]),
    });
    this.market_list.push({
      title: "미국 S&P",
      data: data_yfin.spy,
      // lv: data_yfin.spy[1],
      // levels: get_levels(data_yfin.spy[1]),
    });
    this.market_list.push({
      title: "코스피",
      data: data_yfin.ksp,
      // lv: data_yfin.ksp[1],
      // levels: get_levels(data_yfin.ksp[1]),
    });

    console.log("this.market_list", this.market_list);
  },
  computed: {
    md_HELLO_MSG_1() {
      return this.md(this.$t("#HELLO_MSG_1"));
    },
  },
  methods: {
    get_naver_url(code) {
      if (Number.isInteger(code)) {
        return "https://finance.naver.com/item/main.naver?code=" + code;
      }
      return "https://finance.yahoo.com/quote/" + code;
    },
    get_percent(r) {
      if (r === undefined) return "";
      if (Number.isNaN(r)) return "";
      var p = Math.ceil(r * 100);
      var s = p.toFixed(0);
      // console.log("get_percent", r, s);
      return s;
    },
  },
};
</script>

<style scoped>
.tw-section {
  @apply bg-gray-300 dark:bg-gray-600 dark:text-white shadow rounded-lg px-4 pb-8;
}

.tw-tag {
  @apply bg-gray-600 rounded-full px-2 pt-1 text-white text-xs;
}

.lv_section {
  @apply bg-gray-300 dark:bg-gray-600 rounded-lg px-2 pt-4 pb-2;
}
.lv_title {
  @apply text-xs dark:text-white mb-2;
}
.lv_ticker {
  @apply bg-gray-400 rounded-full text-white text-xs mt-2 px-3 pt-1;
}
.lv_bar {
  @apply flex;
}
.lv_block {
  @apply w-9 h-9 rounded border border-black dark:border-white;
}

.lvtitle {
  @apply flex justify-end items-center w-24;
}
.lv0 {
  @apply w-9 h-9;
}
.lvx {
  @apply flex justify-center items-center bg-white lv_block;
}

/* .lv1 {
  @apply bg-red-600 lv_block;
}
.lv2 {
  @apply bg-red-500 lv_block;
}
.lv3 {
  @apply bg-red-400 lv_block;
}
.lv4 {
  @apply bg-red-300 lv_block;
}

.lv5 {
  @apply bg-yellow-600 lv_block;
}
.lv6 {
  @apply bg-yellow-500 lv_block;
}
.lv7 {
  @apply bg-yellow-400 lv_block;
}
.lv8 {
  @apply bg-yellow-300 lv_block;
}

.lv9 {
  @apply bg-green-600 lv_block;
}
.lv10 {
  @apply bg-green-700 lv_block;
}
.lv11 {
  @apply bg-green-800 lv_block;
}
.lv12 {
  @apply bg-green-900 lv_block;
} */
</style>
